<!-- <button mat-icon-button class="button-langs" [matMenuTriggerFor]="menu">
	<mat-icon>language</mat-icon>
</button> -->

<button class="button-langs" style="color: white;" mat-button [matMenuTriggerFor]="menu" >
	<span matPrefix>{{langSelected | uppercase}}</span>
	<mat-icon>language</mat-icon>
</button>
<!-- {{ langSelected | uppercase }} -->
<mat-menu #menu="matMenu">
	<ng-container *ngFor="let lang of langs">
		<button mat-menu-item (click)="changeLang(lang.value)">
			<span>{{ lang.name | translate }}</span>
		</button>
	</ng-container>
</mat-menu>