import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from "@angular/common/http";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { WidgetComponent } from "./components/widget/widget.component";
// import { ChatBoxComponent } from "./components/chat-box/chat-box.component";
import { SupportIntroComponent } from "./components/chat-box/support-intro/support-intro.component";
import { ServiceRatingComponent } from "./components/service-rating/service-rating.component";
import { MaterialModule } from "../material/material.module";
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { DownloadFileComponent } from "./components/download-file/download-file.component";
import { FormCustomComponent } from "./components/form-custom/form-custom.component";
// import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
// import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
// import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
// import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
// import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
// import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
// import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
// import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
// import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
// import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { WelcomeChatComponent } from "./components/welcome-chat/welcome-chat.component";
import { HomeChatComponent } from "./components/home-chat/home-chat.component";
import { HelpSectionComponent } from "./components/help-section/help-section.component";
import { NewChatSectionComponent } from "./components/new-chat-section/new-chat-section.component";
import {
	NgxGoogleAnalyticsModule,
	NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { QuillModule } from "ngx-quill";
import { LanguageComponent } from "../language/language.component";
import { CarouselComponent } from "./components/chat-box/carousel/carousel.component";
import { SwiperModule } from "swiper/angular";
import { ChatBoxComponent } from "./components/chat-box/chat-box.component";
import { QuickResponseComponent } from "./components/chat-box/quick-response/quick-response.component";
import { BannerChatComponent } from "./components/banner-chat/banner-chat.component";
import { BubbleChatComponent } from "./components/bubble-chat/bubble-chat.component";
import { FooterComponent } from "./components/footer/footer.component";
import { WebChatComponent } from "./components/web-chat/web-chat.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BubbleActionsComponent } from "../shared/components/bubble-actions/bubble-actions.component";
import { MarkdownModule } from "ngx-markdown";
import { ClipboardButtonComponent } from "../shared/components/clipboardButtonComponent/clipboardButtonComponent.component";


const keyGoogleAnalytics = ""; //config[projectId].keyGoogleAnalytics;
const appUrl = "https://signalr.makersclub.brandbot.co"; //config[projectId].webhooks.production.appUrl;

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	let languagePath = "./assets/i18n/";
	// if (environment.production) { languagePath = './assets/brandbot-i18n/'; }
	if (environment.production) {
		languagePath = appUrl + "/assets/i18n/";
	}
	return new TranslateHttpLoader(http, languagePath, ".json");
}

@NgModule({
	declarations: [
		WidgetComponent,
		ChatBoxComponent,
		SupportIntroComponent,
		ServiceRatingComponent,
		UploadFileComponent,
		DownloadFileComponent,
		FormCustomComponent,
		WelcomeChatComponent,
		HomeChatComponent,
		HelpSectionComponent,
		NewChatSectionComponent,
		LanguageComponent,
		CarouselComponent,
		QuickResponseComponent,
		WebChatComponent,
		BannerChatComponent,
		BubbleChatComponent,
		FooterComponent,
	],
	exports: [
		WidgetComponent,
		ChatBoxComponent,
		WebChatComponent,
		BannerChatComponent,
		BubbleChatComponent,
		FooterComponent,
		SupportIntroComponent,
		ServiceRatingComponent,
		WelcomeChatComponent,
		HomeChatComponent,
		HelpSectionComponent,
		NewChatSectionComponent,
		BubbleChatComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MaterialModule,
		SharedModule,
		CoreModule,
		FormsModule,
		ReactiveFormsModule,
		DragDropModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatRadioModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatListModule,
		MatSnackBarModule,
		BrowserAnimationsModule,
		MatDialogModule,
		MatTooltipModule,
		NgxGoogleAnalyticsModule.forRoot(keyGoogleAnalytics),
		QuillModule.forRoot(),
		NgxGoogleAnalyticsRouterModule,
		EmojiModule,
		MatIconModule,
		SwiperModule,
		ClipboardButtonComponent,
		MarkdownModule.forRoot(),
		TranslateModule.forChild({
			defaultLanguage: "en",
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		BubbleActionsComponent,
	],
	providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ChatModule {}
