import {
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { Global } from "../../../core/global-variables/global";
import { AppConfig } from "../../../../../src/app.config";
import SwiperCore, { SwiperOptions, Autoplay, Pagination } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { ChatBoxService } from "../../../core/service/chatBox.service";

SwiperCore.use([Autoplay, Pagination]);

@Component({
	selector: "app-home-chat",
	templateUrl: "./home-chat.component.html",
	styleUrls: ["./home-chat.component.css"],
})
export class HomeChatComponent implements OnInit, OnDestroy {
	@ViewChild("scroll") scroll: ElementRef;
	@ViewChild('swiperHome') swiperComponent?: SwiperComponent;
	@Input() config: AppConfig;
	@Input() global: Global;
	@Output("toggleChat") toggleChat: EventEmitter<any> = new EventEmitter<any>();
	@Output("toogleSizeWidth") toogleSizeWidth: EventEmitter<any> =
		new EventEmitter<any>();

		private _chatBoxService: ChatBoxService = inject(ChatBoxService);

	section: string = "home";
	configSwiper: SwiperOptions;
	isShowFotter = true;


	// currentIndex: number = 0;
	// timeoutId?: number;

	question = [
		// 'Set up the fully customizable Messenger',
		// 'Tickets explained',
		// 'How teammates get notifications',
		// 'News explained'
	];

	newCards = [
		// {
		// 	img:'assets/images/cover.png',
		// 	label:'Outbound Messaging',
		// 	title:'New Email Improvements',
		// 	description: `Hi there! We've added a number of new formatting capabilities to our email
		// 	product 📧 You can now:Add a spacer block`,
		// }
	];
	// footerHome = [
	// 	{ title: "Home", icon: "home", section: "home" },
	// 	{ title: "Message", icon: "question_answer", section: "chat" },
	// 	{ title: "Help", icon: "contact_support", section: "help" },
	// ];
	appUrl: string = "";

	constructor() {
		
		this.configSwiper = {
			loop: true,
			spaceBetween: 30,
			slidesPerView: 1,
			pagination: {
				clickable: true,
			},
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			}
		};

	}

	ngOnInit(): void {
		this.appUrl = this.global.appUrl;
		if (sessionStorage.getItem('bbwidget_section') == 'CHAT') {
			this.changeSection('chat')
		}
	}

	ngOnDestroy(): void {
		// window.clearTimeout(this.timeoutId);
	}

	changeSection(type: string) {
		this.section = type;
		if (type === "chat" || this.config.showOnlyChat) {
			// localStorage.setItem("isHomeChat", String(false));
			// localStorage.setItem("isOnlyChat", String(true));
			this.toggleChat.emit({ isHomeChat: false, isOnlyChat: true });
			sessionStorage.setItem('bbwidget_section', 'CHAT');
		} else {
			// localStorage.setItem("isHomeChat", String(true));
			// localStorage.setItem("isOnlyChat", String(false));
		}
	}

	changeRize() {
		this.toogleSizeWidth.emit();
		this.isShowFotter = !this.isShowFotter;
	}

	public close() {
		this._chatBoxService.closeChat();
	}

	toogleFooter() {
		this.isShowFotter = !this.isShowFotter;
	}


	// resetTimer() {
	// 	if (this.timeoutId) {
	// 		window.clearTimeout(this.timeoutId);
	// 	}
	// //	this.timeoutId = window.setTimeout(() => this.goToNext(), 3000); animaciones del carrousel
	// }

	// goToSlide(slideIndex: number): void {
	// 	this.resetTimer();
	// 	this.currentIndex = slideIndex;
	// }

	getCurrentSlideUrl(url: string) {
		return `url('${url}')`;
	}

	openPayload(payload: string) {
		if (payload) {
			window.open(payload, '_blank')
		}
	}


}
