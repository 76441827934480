import { Injectable, EventEmitter } from "@angular/core";
import {
	HttpTransportType,
	HubConnection,
	HubConnectionBuilder,
} from "@microsoft/signalr";
import { Global } from "../global-variables/global";
import { environment } from "../../../environments/environment";
import { PsMessage } from "../interfaces/IMessage";

@Injectable({
	providedIn: "root",
})
export class SignalRService {
	// Declare the variables
	private _hubConnection: HubConnection;
	public isLoading: Boolean;

	// create the Event Emitter
	public messageReceived: EventEmitter<PsMessage>;
	public connectionEstablished: EventEmitter<Boolean>;
	public connectionFailed: EventEmitter<Boolean>;
	public onRestart: EventEmitter<any>;

	constructor(private global: Global) {
		// Constructor initialization
		this.connectionEstablished = new EventEmitter<Boolean>();
		this.connectionFailed = new EventEmitter<boolean>();
		this.messageReceived = new EventEmitter<PsMessage>();
		this.onRestart = new EventEmitter<any>();
		this.isLoading = true;
	}

	public initConnection() {
		// if (!environment.production) { console.log('socketUrl', this.global.socketUrl); }

		console.log(this.global)
		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${this.global.socketUrl}/chat`, {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets,
			})
			.build();

		this.registerOnServerEvents();
	}

	// method to hit from client
	public sendMessage(message: any) {
		// if (!this.global.production) { console.log("message", message); }
		// server side hub method using proxy.invoke with method name pass as param
		this._hubConnection
			.invoke("SendPSMessageToServer", JSON.stringify(message))
			.catch((error: any) => {
				console.error(error);
				this.global.connectionAttempts = 3;
			});
		if (message.Content !== "INICIO") {
			this.global.interaction = true;
		}
	}

	notificationError() {
		const psMessage: PsMessage = {
			socialId: "",
			socialName: "",
			type: "text",
			content: "No se pudo entregar tu mensaje, por favor inténtalo más tarde",
			channelAccount: "",
			channelId: "",
			languageId: "1",
		};
		this.messageReceived.emit(psMessage);
	}

	// check in the browser console for either signalr connected or not
	public startConnection(): void {
		// console.log(`Start connection on ${new Date().toTimeString()}`);

		this.global.connectionAttempts++;
		this._hubConnection
			.start()
			.then((data: any) => {
				this.connectionEstablished.emit(true);
				this.isLoading = false;
			})
			.catch((error: any) => {
				this.connectionFailed.emit(true);
				// Reconnect
				if (this.global.connectionAttempts < 1) {
					this.startConnection();
				}
			});
	}

	public removeConnection(): void {
		this._hubConnection.stop();
	}

	public registerOnServerEvents(): void {
		this._hubConnection.on("Send", (message) => {

			// if (!this.global.production) { }
			// if (!this.global.restart) {
				this.messageReceived.emit(message);
			// } else {
			// 	this.onRestart.emit();
			// }
		});
	}
}
