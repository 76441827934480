<!-- <div [@fadeInOut]="state" class="drop-container" (dragover)="dragOver($event)" (drop)="drop($event)" (dragleave)="dragLeave($event)">
	Arrastra y suelta un archivo aquí
  </div>
  {{state}} -->
<div
	[@fadeInOut]="state"
	class="dropzone"
	(drop)="onFileDropped($event)"
	(dragover)="dragOver($event)"
	(dragleave)="dragLeave($event)"
>
	<input
		type="file"
		#fileDorgRef
		id="fileDropRef"
		(change)="fileBrowseHandler($event.target.files, $event.target)"
	/>
	<img src="assets/images/upload-cloud.png" alt="" />
	<div
		[@fadeInOut]="state"
		[class.op-05]="state === 'out'"
		[class.op-1]="state === 'in'"
	>
		<h3>Arrastre y suelte el archivo aquí</h3>
		<!-- <ng-container *ngIf="config?.showAttachmentFile || true" >
			<mat-select [value]="fileSelected" (selectionChange)="selectAttachmentFile()" >
				<mat-option value="">Select attachment</mat-option>
				<ng-container *ngFor="let file of fileSelector">
					<mat-option [value]="file">{{file.name}}</mat-option>
				</ng-container>
			</mat-select>
			<h3>or</h3>
		</ng-container> -->
		<label for="fileDropRef">Archivo</label>
	</div>
</div>
