import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import SwiperCore, { SwiperOptions, Autoplay, Pagination } from "swiper";
import { SwiperComponent } from "swiper/angular";
// import { APP_CONFIG, AppConfig } from "../../../../../src/app.config";
SwiperCore.use([Pagination]);

@Component({
	selector: "chat-carousel",
	templateUrl: "./carousel.component.html",
	styleUrls: ["./carousel.component.css"],
})
export class CarouselComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('swiperCarousel') swiperComponent?: SwiperComponent;
	@Input() helpResize: string;
	@Input() onlyImage = true;
	@Input() config: any[] = [];
	@Input() presentationMode: string = 'grid';
	@Input() elements: any[] = [];
	@Input() chatView: string = null
	@Input() dimensionActual: string;
	configSwiper: SwiperOptions;

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
	) {
		this.configSwiper = {
			loop: true,
			spaceBetween: 30,
			slidesPerView: 1,
			pagination: {
				clickable: false,
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			},
		};
	}

	ngOnInit(): void {

		this.configSwiper['pagination']['clickable'] = this.elements?.length > 2 ? true : false;
	}

	ngAfterViewInit(): void {
		// this.updateSliderHeight();
		this.swipperUpdate();
		this._changeDetectorRef.markForCheck();
	}

	swipperUpdate() {
		if (this.presentationMode != 'grid') {
			if (this.chatView == 'BANNER') {
				console.log({ dimensionActual: this.dimensionActual });
				if (this.dimensionActual == 'sm') {
					this.configSwiper.slidesPerView = 1;
					this.swiperComponent?.updateParameter('slidesPerView', 1);
				} else {
					this.configSwiper.slidesPerView = 2;
					this.swiperComponent?.updateParameter('slidesPerView', 2);
				}
				this.configSwiper.spaceBetween = 10;
				this.swiperComponent?.updateParameter('slidesPerView', 2);
				this.swiperComponent?.updateParameter('spaceBetween', 10);
				this.swiperComponent?.updateSwiper(this.configSwiper);
			} else if (this.chatView == 'WEB') {
				this.configSwiper.slidesPerView = 3;
				this.swiperComponent?.updateParameter('slidesPerView', 3);
				this.swiperComponent?.updateSwiper(this.configSwiper);
			} else {
				if (this.helpResize == 'out') {
					this.configSwiper.slidesPerView = 2;
					this.swiperComponent?.updateParameter('slidesPerView', 2);
					this.swiperComponent?.updateSwiper(this.configSwiper);
				} else {
					this.configSwiper.slidesPerView = 1;
					this.swiperComponent?.updateParameter('slidesPerView', 1);
					this.swiperComponent?.updateSwiper(this.configSwiper);
				}
			}
			this.swiperComponent?.swiperRef.update();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {

		if ('helpResize' in changes) {
			this.helpResize = changes.helpResize.currentValue;
			if (!!this.helpResize) {
				this.swipperUpdate();
			}
			this._changeDetectorRef.markForCheck();
		}

		if ('onlyImage' in changes) {
			this.onlyImage = changes.onlyImage.currentValue;
			this._changeDetectorRef.markForCheck();
		}

		if ('config' in changes) {
			this.config = changes.config.currentValue;
			this._changeDetectorRef.markForCheck();
		}

		if ('presentationMode' in changes) {
			this.presentationMode = changes.presentationMode.currentValue;
			this._changeDetectorRef.markForCheck();
		}

		if ('elements' in changes) {
			this.elements = changes.elements.currentValue;
			this._changeDetectorRef.markForCheck();
		}

		if ('chatView' in changes) {
			this.chatView = changes.chatView.currentValue;
			this._changeDetectorRef.markForCheck();
		}

		if ('presentationMode' in changes) {
			this.presentationMode = changes.presentationMode.currentValue;
			this._changeDetectorRef.markForCheck();
		}
	}

	openUrl(botton: any) {
		console.log({ botton })
		// window.open(url, '_blank')
	}

}
