import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NotificationComponent } from "../notification-component/notification-component.component";


@Component({
	selector: "app-clipboard-button-component",
	standalone: true,
	imports: [CommonModule, MatIconModule, NotificationComponent],
	templateUrl: "./clipboardButtonComponent.component.html",
	styleUrl: "./clipboardButtonComponent.component.css",
})
export class ClipboardButtonComponent {

	public isVisible = signal<boolean>(false);

	public onClick() {
		// alert("Copied clipboard");
		this.isVisible.set(true);
		setTimeout(() => {
			this.isVisible.set(false);
		}, 1000);
	}
}
