import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from "../../../../../src/app.config";
import { Global } from "../../../../../src/app/core/global-variables/global";

@Component({
  selector: 'chat-banner-chat',
  templateUrl: './banner-chat.component.html',
  styleUrls: ['./banner-chat.component.css']
})
export class BannerChatComponent implements OnInit {
  @Input() config: AppConfig;
  @Input() global: Global;
  constructor() { }

  ngOnInit(): void {
  }

}
