<div class="w-full bbwidget-container-master full-container" [style.--backgroundWeb]="config.backgroundWeb">
	<div class="flex items-center justify-center w-full mx-auto my-0">
		<mat-card class="md:w-[80%] md:h-[90%] md:rounded-[16px] p-0 big-shadow full-size">
			
			<div *ngIf="isHomeChat" class="bbwidget-container-home">
				<app-home-chat [config]="config" [global]="global" (toggleChat)="toggleChat($event)" style="overflow: hidden"
					(toogleSizeWidth)="resize()"></app-home-chat>
			</div>

			<ng-container *ngIf="showChat">
				<chat-chat-box [global]="global" style="height: 100%;" 
				[helpResize]="helpResize"
				[isMinimizeChat]="isMinimizeChat"
				(isShowWelcomeChat)="isShowWelcomeChat = false"
				(toggleChat)="toggleChat($event)"
				(clearRezise)="clearRezise($event)"
				(minimize)="minimize()"></chat-chat-box>
			</ng-container>
		</mat-card>
	</div>
</div>