<div
	class="bbwidget-support"
	[ngStyle]="{ 'background-color': config.chatBackground }"
>
	<div class="bbwidget-support-header">
		<h1
			class="bbwidget-support-title"
			[ngStyle]="{ color: config.supportTitleColor }"
		>
			{{ "_SUPPORT_INTRO.TITLE" | translate }}
		</h1>
		<p
			class="bbwidget-support-subtitle"
			[ngStyle]="{ color: config.supportSubtitleColor }"
		>
			{{ "_SUPPORT_INTRO.SUBTITLE" | translate }}
		</p>
	</div>
	<div
		class="bbwidget-support-content"
		[ngStyle]="{ 'background-color': config.supportMessageBackground }"
	>
		<div>
			<p
				class="bbwidget-support-message"
				[ngStyle]="{ color: config.supportContentColor }"
			>
				{{ "_SUPPORT_INTRO.MESSAGE" | translate }}
			</p>
		</div>
		<div>
			<button
				mat-button
				class="bbwidget-support-start"
				[ngStyle]="{
					'background-color': config.confirmButtonBackground,
					color: config.confirmButtonColor
				}"
				(click)="startChat()"
			>
				{{ "_SUPPORT_INTRO.BTNTEXT" | translate }}
			</button>
		</div>
	</div>
	<div class="bbwidget-logo">
		<img src="{{ brandbotAvatar }}" alt="MakersClub logo" />
	</div>
</div>
