import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import { environment } from "../../../../../environments/environment";

@Component({
	selector: "chat-support-intro",
	templateUrl: "./support-intro.component.html",
	styleUrls: ["./support-intro.component.css"],
})
export class SupportIntroComponent implements OnInit, OnChanges {
	@Output("startChat") _startChat: EventEmitter<any> = new EventEmitter<any>();
	@Input() config: any;

	brandbotAvatar = "../../assets/images/brandbot/widget_btn_img.png";

	constructor() {}

	ngOnInit(): void {
		if (environment.production) {
			this.brandbotAvatar = "../../assets/images/brandbot/widget_btn_img.png";
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!!changes.config) {
			if (!!changes.config.currentValue) {
				this.config = changes.config.currentValue;
			}
		}
	}

	startChat(): void {
		this._startChat.emit(0);
	}
}
