import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, of } from "rxjs";
// const PREFERENCES = require("../../../clients-config.json");

@Injectable({
	providedIn: "root",
})
export class SharedPropertiesGeneral {
	// private configProject: any = PREFERENCES[environment.projectId];
	/// POR LO QE VEO ESTO NADIE LO USA
	// private configProject$: Observable<any> = of(this.configProject);
	/// POR LO QE VEO ESTO NADIE LO USA
	setConfigProject(data: any) {
		// this.configProject = data ? data : PREFERENCES[environment.projectId];
	}

	// obtenerDatos(): Observable<any> {
	// 	return this.configProject$;
	// }
}
