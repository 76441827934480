<div
	class="bbwidget-home-chat animated pulse"
	[class.home-shadow]="'chat' === section"
>
	<ng-container *ngIf="section === 'home'">
		<div class="bbwidget-home-chat-body scroll">
			<div class="h-[235px] relative">
				<div
					class="flex flex-col justify-between absolute w-[100%] h-[100%] py-[32px] px-[20px] z-[2]"
				>
					<div style="display: flex; justify-content: space-between">
						<div
							class="bbwidget-background-header-icon"
							[style.--toolbarLogoBackground]="config?.toolbarLogoBackground"
						>
							<img class="bbwidget-img-class" [src]="config?.toolbarLogo" />
						</div>
						<div class="flex items-center justify-center">
							<app-language></app-language>

							<button
							class="text-xl text-gray-100"
							mat-icon-button
							(click)="close()"
							*ngIf="config.chatView == 'BUBBLE'"
						>
							<mat-icon>keyboard_arrow_down</mat-icon>
						</button>
						</div>
					</div>
					<div style="padding-top: 30px">
						<div
							class="bbwidget-background-header-title"
							[style.--homeContentColor]="
								config.homeContentColor | safe: 'style'
							"
						>
							{{ "Welcome" | translate }}
						</div>
						<div
							class="bbwidget-background-header-subtitle"
							[style.--homeContentColor]="
								config.homeContentColor | safe: 'style'
							"
						>
							{{ "How_can_help_you" | translate }}
						</div>
					</div>
				</div>
				<div
					class="w-full h-[445px] fixed header-background-gradient"
					[style.--homeBackgroundDegradateFirst]="
						config.homeBackgroundDegradateFirst | safe: 'style'
					"
					[style.--homeBackgroundDegradateSecond]="
						config.homeBackgroundDegradateSecond | safe: 'style'
					"
					[style.--homeBackgroundDegradateThird]="
						config.homeBackgroundDegradateThird | safe: 'style'
					"
				>
					<div
						class="absolute top-[305px] left-0 h-[140px] w-full opacity-[1] z-0 bg-gradient-to-t from-white to-transparent"
					></div>
					<div
						class="absolute top-[443px] left-0 h-full w-full z-0 bg-white"
					></div>
				</div>
			</div>
			<div class="bbwidget-home-chat-body-cards">
				<div
					class="bbwidget-home-chat-body-card_one"
					(click)="changeSection('chat')"
					[style.--homeButtonBackgroundDegradateFirst]="
						config.homeButtonBackgroundDegradateFirst | safe: 'style'
					"
					[style.--homeButtonBackgroundDegradateSecond]="
						config.homeButtonBackgroundDegradateSecond | safe: 'style'
					"
					[style.--homeButtonBackgroundDegradateThird]="
						config.homeButtonBackgroundDegradateThird | safe: 'style'
					"
					[style.--homeButtonColorText]="
						config.homeButtonColorText | safe: 'style'
					"
				>
					<div class="bbwidget-section-message">
						<div class="bbwidget-section-message-title">
							{{ "SEND_mesaage" | translate }}
						</div>
						<div class="bbwidget-section-message-text">
							{{ "OUR_reply" | translate }}
						</div>
					</div>
					<div>
						<mat-icon class="icon-search">send</mat-icon>
					</div>
				</div>

				<ng-container *ngFor="let button of config.buttonslinkHome">
					<a
						[href]="button.url"
						target="_blank"
						style="text-decoration: none; color: inherit"
					>
						<div
							class="bbwidget-home-chat-body-card_one"
							[style.--homeButtonBackgroundDegradateFirst]="
								config.homeButtonBackgroundDegradateFirst | safe: 'style'
							"
							[style.--homeButtonBackgroundDegradateSecond]="
								config.homeButtonBackgroundDegradateSecond | safe: 'style'
							"
							[style.--homeButtonBackgroundDegradateThird]="
								config.homeButtonBackgroundDegradateThird | safe: 'style'
							"
							[style.--homeButtonColorText]="
								config.homeButtonColorText | safe: 'style'
							"
						>
							<div class="bbwidget-section-message">
								<div class="bbwidget-section-message-title">
									{{ button.title | translate }}
								</div>
								<div class="bbwidget-section-message-text">
									{{ button.subtitle | translate }}
								</div>
							</div>
							<div>
								<mat-icon class="bbwidget-icon-search">{{
									button.icon
								}}</mat-icon>
							</div>
						</div>
					</a>
				</ng-container>

				<div
					class="bbwidget-content-slider"
					[ngStyle]="{
						height: config?.chatView === 'WEB' ? '75vh' : '200px',
						'max-height': config?.chatView === 'WEB' ? '500px' : 'none'
					}"
				>
					<swiper effect="fade" #swiperHome [config]="configSwiper">
						<ng-container *ngFor="let slide of config.homeSlides">
							<ng-template swiperSlide>
								<ng-container *ngIf="slide.type === 'image'">
									<div
										class="bbwidget-slide-home"
										(click)="openPayload(slide.payload)"
										[class.cursor-pointer]="!!slide.payload"
										[ngStyle]="{
											'background-image': getCurrentSlideUrl(slide.url)
										}"
									></div>
								</ng-container>
								<ng-container *ngIf="slide.type === 'video'">
									<video controls>
										<ng-container *ngFor="let src of slide.sources">
											<source
												[src]="src.url"
												type="{{ src.type }}; codecs=&quot;{{
													src.codecs
												}}&quot;"
											/>
										</ng-container>
									</video>
								</ng-container>
							</ng-template>
						</ng-container>
					</swiper>
				</div>

				<div class="home-chat-body-section-search" *ngIf="question.length > 0">
					<button class="searching">
						<span>Search for help</span>
						<mat-icon class="icon-search">search</mat-icon>
					</button>
					<div class="question-frecuently" *ngFor="let item of question">
						<div class="question-text">
							{{ item }}
						</div>
						<mat-icon>navigate_next</mat-icon>
					</div>
				</div>
				<div class="card-last-new" *ngFor="let item of newCards">
					<img class="img-card" [src]="item.img" />
					<div class="card-body-new">
						<div class="card-label">
							<div class="card-label-new">
								{{ item.label }}
							</div>
						</div>
						<div class="card-body-footer">
							<div class="card-body-text">
								<div class="card-new-title">{{ item.title }}</div>
								<div class="card-new-body-text">
									<div class="card-new-text">{{ item.description }}</div>
								</div>
							</div>
							<div class="card-body-next-icon">
								<mat-icon>navigate_next</mat-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<app-help-section
		[config]="config"
		(toogleSizeWidth)="changeRize($event)"
		class="help-section"
		*ngIf="section === 'help'"
	></app-help-section>
	<chat-footer
		[config]="config"
		(changeSection)="changeSection($event)"
	></chat-footer>
</div>
