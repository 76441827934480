import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	Output,
	EventEmitter,
	Inject,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
	selector: "chat-form-custom",
	templateUrl: "./form-custom.component.html",
	styleUrls: ["./form-custom.component.css"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCustomComponent implements OnInit {
	formContent: any;
	// @Input() config: any;
	// @Output() sendform: EventEmitter<any> = new EventEmitter<any>();

	formCustom: FormGroup;

	constructor(
		private _formBuilder: FormBuilder,
		public _matDialogRef: MatDialogRef<FormCustomComponent>,
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      formContent: any;
    },
		private _changeDetectorRef: ChangeDetectorRef,
	) {
		this.formCustom = this._formBuilder.group({});
		this.formContent = this._data.formContent;
	}

	// ngOnChanges(changes: SimpleChanges) {
	// 	if ("formContent" in changes) {
	// 		this.formCustom = this._formBuilder.group({});
	// 		console.log("formContent", changes.formContent.currentValue);
	// 		this.formContent = changes.formContent.currentValue;
	// 		this.formContent.fields.map((field: any) => {
	// 			const value =
	// 				field.type === "select" || field.type === "radio_button"
	// 					? field.options[0].payload
	// 					: "";
	// 			this.formCustom.addControl(field.id, new FormControl(value));
	// 			this.formCustom
	// 				.get(field.id)
	// 				.addValidators(Validators.pattern(`${field.regex}`));
	// 			if (field.isRequired) {
	// 				this.formCustom.get(field.id).addValidators(Validators.required);
	// 			}
	// 		});

	// 	}
	// }

	ngOnInit(): void {
		this.formContent.fields.map((field: any) => {
			const value =
				field.type === "select" || field.type === "radio_button"
					? field.options[0].payload
					: "";
			this.formCustom.addControl(field.id, new FormControl(value));
			this.formCustom
				.get(field.id)
				.addValidators(Validators.pattern(`${field.regex}`));
			if (field.isRequired) {
				this.formCustom.get(field.id).addValidators(Validators.required);
			}
		});

		this._changeDetectorRef.markForCheck();
	}

	sendFormCompled(event) {
		// this.sendform.emit(event);
	}
}
