import { CommonModule } from "@angular/common";
import { Component, input, output } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: 'app-bubble-actions',
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule
	],
	templateUrl: './bubble-actions.component.html',
	styleUrl: './bubble-actions.component.css',
})
export class BubbleActionsComponent { 

		public data = input<string>('diego')
		public emitData = output<any>()

}
