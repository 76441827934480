<div class="chat-footer" [style.--footerBackgroundColor]="config.footerBackgroundColor">
	<ng-container *ngFor="let item of config.footerIcons">
		<div *ngIf="item?.visible" class="option-header"
			[ngClass]="{'font-semibold option-selection': item?.section === section}"
			[style.--footerTextColor]="item?.section === section ? config.footerTextColorSelected:  config.footerTextColor"
			[style.--footerTextColorSelected]="config.footerTextColorSelected" (click)="changeSectionSend(item?.section)">
			<mat-icon>{{ item.icon }}</mat-icon>
			<div>{{ item.title | translate }}</div>
		</div>
	</ng-container>
</div>