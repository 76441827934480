import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Global } from "../../../core/global-variables/global";
import { AppConfig } from "../../../../app.config";
import { PreferenceService } from "../../../core/service/preference.service";
import { Subject, takeUntil } from "rxjs";
// const config = require("../../../../clients-config.json");
@Component({
	selector: "app-welcome-chat",
	templateUrl: "./welcome-chat.component.html",
	styleUrls: ["./welcome-chat.component.css"],
})
export class WelcomeChatComponent implements OnInit {
	@Output() toogleWelcomeChat = new EventEmitter<boolean>();
	@Output("selectOption") selectOption: EventEmitter<any> =
		new EventEmitter<any>();
	@Output("closeBuble") closeBuble: EventEmitter<any> = new EventEmitter<any>();
	@Output("openOnlyHome") openOnlyHome: EventEmitter<any> =
		new EventEmitter<any>();
	@Input() list_message: any;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	title = "STRART_MESAGE_1";
	options: any = [
		{
			title: "STRART_MESAGE_YES",
			onClickACtion: (item) => this.selectOptionWelcomeChat(item),
		},
		{ title: "STRART_MESAGE_NO", onClickACtion: () => this.closeChatBuble() },
	];
	config: AppConfig

	constructor(
		public translate: TranslateService,
		private _preferenceService: PreferenceService,
		private _changeDetectorRef: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {

		this._preferenceService.config$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config: any) => {
				this.config = config

				this.options[0] = {
					title: "STRART_MESAGE_YES",
					onClickACtion: () => {
						sessionStorage.setItem('bbwidget_section', 'CHAT');
						this.selectOption.emit(null);
					},
				};

				this._changeDetectorRef.markForCheck();

			});

	}

	close() {
		this.toogleWelcomeChat.emit(false);
	}


	selectOptionWelcomeChat(data) {
		this.selectOption.emit(data);
	}

	closeChatBuble() {
		this.closeBuble.emit();
	}
}
