import { NgModule } from "@angular/core";
// import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
// import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatIconModule } from "@angular/material/icon";
// import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
// import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
// import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
// import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import {MatCardModule} from '@angular/material/card';
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
	imports: [
		MatSidenavModule,
		MatCardModule,
		MatToolbarModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatInputModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatSelectModule,
	],
	exports: [
		MatSidenavModule,
		MatCardModule,
		MatToolbarModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatInputModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatSelectModule,
	],
})
export class MaterialModule {}
