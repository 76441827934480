<div class="flex relative flex-col flex-auto md:w-160 md:min-w-[47rem] max-h-[47rem] -m-6 overflow-y-auto">

	<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
		<div class="text-lg font-medium">{{formContent?.title}}</div>
		<button mat-icon-button (click)="_matDialogRef.close()" [tabIndex]="-1">
			<mat-icon class="text-current">close</mat-icon>
		</button>
	</div>

	<form class="w-full p-4 sm:p-8 overflow-y-auto" [formGroup]="formCustom">
		<div class="flex flex-wrap">
			<ng-container *ngFor="let field of formContent?.fields">
				<mat-form-field appearance="outline"
					*ngIf="field.type === 'text' || field.type === 'text_area' || field.type === 'select'">
					<mat-label class="label-form">{{field.label}}</mat-label>

					<ng-container *ngIf="field.type === 'text'">
						<input matInput [formControlName]="field.id" [required]="field.isRequired" [name]="field.trackingName">
					</ng-container>

					<ng-container *ngIf="field.type === 'text_area'">
						<textarea matInput cols="30" rows="10" [formControlName]="field.id" [required]="field.isRequired"
							[name]="field.trackingName"></textarea>
					</ng-container>

					<ng-container *ngIf="field.type === 'select'">
						<ng-container *ngIf="field.id === 'FORM_PROVINCE'">
							<mat-select [formControlName]="field.id" (selectionChange)="selectProvince($event)" #provinceSelect>
								<ng-container *ngFor="let option of provinces">
									<mat-option [value]="option.provincia">
										{{option.provincia}}
									</mat-option>
								</ng-container>
							</mat-select>
							<input type="hidden" [name]="field.trackingName" [value]="provinceSelect.value">
						</ng-container>
						<ng-container *ngIf="field.id === 'FORM_CITY'">
							<mat-select [formControlName]="field.id" #citySelect>
								<ng-container *ngFor="let option of selectedProvince?.cantones">
									<mat-option [value]="option.canton">{{option.canton}}</mat-option>
								</ng-container>
							</mat-select>
							<input type="hidden" [name]="field.trackingName" [value]="citySelect.value">
						</ng-container>
						<ng-container *ngIf="field.id !== 'FORM_PROVINCE' && field.id !== 'FORM_CITY'">
							<mat-select [formControlName]="field.id" (selectionChange)="onFormSelectChange($event)" #matSelect>
								<ng-container *ngFor="let option of field.options">
									<mat-option [value]="option.payload">{{option.title}}</mat-option>
								</ng-container>
							</mat-select>
							<input type="hidden" [name]="field.trackingName" [value]="matSelect.value">
						</ng-container>
					</ng-container>

					<mat-error *ngIf="formCustom.get(field.id).hasError('required')">
						Requerido
					</mat-error>

					<mat-error *ngIf="formCustom.get(field.id).hasError('pattern')">
						El formato no es válido
					</mat-error>

				</mat-form-field>

				<mat-form-field appearance="outline"
					*ngIf="field.type === 'select' && field.id === 'REGISTER_SPECIALITY' && !!showSpecialityInput">
					<mat-label class="label-form">Describa su especialidad</mat-label>
					<input matInput [(ngModel)]="specialityValue" [ngModelOptions]="{standalone: true}" required
						[name]="field.trackingName">
				</mat-form-field>

				<ng-container *ngIf="field.type === 'radio_button'">
					<mat-label class="label-form">{{field.label}}</mat-label>
					<div class="mat-form-field-wrapper pt-2">
						<mat-radio-group [formControlName]="field.id" class="flex py-5" [name]="field.trackingName">
							<ng-container *ngFor="let option of field.options">
								<mat-radio-button class="mx-1" [value]="option.payload">
									{{option.title}}
								</mat-radio-button>
							</ng-container>
						</mat-radio-group>
					</div>
				</ng-container>

				<ng-container *ngIf="field.type === 'date'">
					<mat-form-field appearance="outline" class="content-form-field form-date" (click)="picker.open()">
						<mat-label class="label-form">{{field.label}}</mat-label>
						<input matInput [formControlName]="field.id" [matDatepicker]="picker" [required]="field.isRequired" readonly
							[min]="minDate" [name]="field.trackingName">
						<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</ng-container>

				<ng-container *ngIf="field.type === 'time'">
					<mat-form-field appearance="outline" class="content-form-field form-time">
						<mat-label class="label-form">{{field.label}}</mat-label>
						<input matInput [formControlName]="field.id" [ngxTimepicker]="pickerTime" [min]="field.min"
							[max]="field.max" [required]="field.isRequired" aria-label="Append to input" [name]="field.trackingName"
							[disableClick]="false" readonly>
						<ngx-material-timepicker #pickerTime [appendToInput]="true"></ngx-material-timepicker>
					</mat-form-field>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="formContent.enableTerms">
				<mat-checkbox name="acceptTerms" [(ngModel)]="acceptTerms" ngDefaultControl
					[ngModelOptions]="{standalone: true}" [color]="'primary'">
					He leído y acepto <a (click)="openTermsAndConditions()">Términos y condiciones</a>
				</mat-checkbox>
			</ng-container>
		</div>
	</form>

	<!-- <div class="w-full overflow-y-auto">
		<div class="flex flex-col flex-0 items-start w-full h-auto p-6 pt-0 sm:p-8 sm:pt-0">

			<div class="z-20 w-full flex items-center justify-between px-6 py-4 md:px-8 cursor-pointer border-b"
				(click)="selectedProduct = product">
				<div class="flex items-center w-full">
					<div class="w-auto ml-4">
						<div class="font-medium leading-5 truncate">
							{{product.name}}
						</div>
					</div>
				</div>
			</div>

		</div>

	</div> -->



	<div class="flex flex-0 justify-between items-center h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 border-t">

		<button mat-stroked-button class="mx-1" (click)="_matDialogRef.close()" [color]="'warn'" [tabIndex]="-1">
			{{'CANCEL' | translate}}
		</button>

		<button mat-flat-button class="mx-1" (click)="null" [color]="'primary'" [tabIndex]="-1">
			{{ 'ACCEPT' | translate}}
		</button>
	</div>
</div>
