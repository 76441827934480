import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { QuestionsService } from "../../.././core/service/questions.service";

@Component({
	selector: "app-help-section",
	templateUrl: "./help-section.component.html",
	styleUrls: ["./help-section.component.css"],
})
export class HelpSectionComponent implements OnInit {
	@Output("toogleSizeWidth") toogleSizeWidth: EventEmitter<any> =
		new EventEmitter<any>();
	@Input() config: any;
	title = null;
	background = "#0057ff";
	isList = true;
	questionHelp = [];
	content: any;
	questions$: Observable<any[]>;
	constructor(
		private sanitizer: DomSanitizer,
		private _questionService: QuestionsService,
	) {}

	ngOnInit(): void {
		this.questions$ = this._questionService.questions$;
		this.getTransactions();
	}

	getTransactions() {
		const { projectId } = this.config;

		this._questionService.getQuestions(projectId).subscribe((response) => {
			console.log(response.data);
			this.questionHelp = this.questionHelp.concat(response.data);
			console.log(this.questionHelp);
		});
	}

	openDetail(item) {
		console.log({ item: item.content });
		this.isList = false;
		this.content = item.content;
		this.title = item.title;
		this.toogleSizeScreen();
	}

	closeOpenDetail() {
		this.isList = true;
		this.toogleSizeScreen();
	}

	toogleSizeScreen() {
		this.toogleSizeWidth.emit();
	}
}
