<div
	id="service-rating"
	*ngIf="!thankYouMsg"
	[ngStyle]="{
		'background-image': config.backgroundImage
			? 'url(' + config.chatBackground + ')'
			: 'linear-gradient(to right top, ' +
			  config.chatBackgroundColor +
			  ', ' +
			  config.chatBackgroundColor +
			  ')'
	}"
>
	<div class="service-rating-title">
		<h3
			[translate]="'_RATE_SERVICE.TITLE'"
			[translateParams]="{ value: config.projectId }"
		></h3>
	</div>
	<div class="service-rating-points">
		<mat-button-toggle-group
			name="rate"
			[(ngModel)]="score"
			aria-label="Calificación"
		>
			<mat-button-toggle
				value="1"
				[ngStyle]="{
					'background-color': config.scoreBackgroundColor,
					color: config.scoreTextColor,
					'border-color': config.scoreBorderColor
				}"
				(click)="changeStyle($event)"
				>1</mat-button-toggle
			>
			<mat-button-toggle
				value="2"
				[ngStyle]="{
					'background-color': config.scoreBackgroundColor,
					color: config.scoreTextColor,
					'border-color': config.scoreBorderColor
				}"
				(click)="changeStyle($event)"
				>2</mat-button-toggle
			>
			<mat-button-toggle
				value="3"
				[ngStyle]="{
					'background-color': config.scoreBackgroundColor,
					color: config.scoreTextColor,
					'border-color': config.scoreBorderColor
				}"
				(click)="changeStyle($event)"
				>3</mat-button-toggle
			>
			<mat-button-toggle
				value="4"
				[ngStyle]="{
					'background-color': config.scoreBackgroundColor,
					color: config.scoreTextColor,
					'border-color': config.scoreBorderColor
				}"
				(click)="changeStyle($event)"
				>4</mat-button-toggle
			>
			<mat-button-toggle
				value="5"
				[ngStyle]="{
					'background-color': config.scoreBackgroundColor,
					color: config.scoreTextColor,
					'border-color': config.scoreBorderColor
				}"
				(click)="changeStyle($event)"
				>5</mat-button-toggle
			>
		</mat-button-toggle-group>
		<p
			style="font-size: 14px; text-align: center; margin-top: 20px"
			[translate]="'_RATE_SERVICE.SCORE_NOTE'"
		></p>
	</div>
	<div class="service-rating-comment">
		<textarea
			type="text"
			autocomplete="off"
			placeholder="{{ '_RATE_SERVICE.INPUT_PLACEHOLDER' | translate }}"
			[(ngModel)]="comment"
		></textarea>
	</div>
	<div class="service-rating-send">
		<button
			mat-flat-button
			(click)="SendServiceRating()"
			[ngStyle]="{
				'background-color': config.accentBackgroundColor,
				color: config.accentColor
			}"
		>
			{{ "_RATE_SERVICE.SEND_BTN" | translate }}
		</button>
	</div>
</div>

<div class="bbwidget-thank-you-message" *ngIf="thankYouMsg">
	<p>{{ "_RATE_SERVICE.THANK_YOU_MSG" | translate }}</p>
</div>
