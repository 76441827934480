@if (config?.notificationAudio) {
	<audio [src]="config.notificationAudio" id="saludo"></audio>
}
<ng-container *ngIf="startChat">
	<ng-container [ngSwitch]="config.chatView">
		<ng-container *ngSwitchCase="'WEB'">
			<chat-web-chat [config]="config" [global]="global"> </chat-web-chat>
		</ng-container>
		<ng-container *ngSwitchCase="'BUBBLE'">
			<chat-bubble-chat [global]="global"> </chat-bubble-chat>
		</ng-container>
		<ng-container *ngSwitchCase="'BANNER'">
			<chat-banner-chat [config]="config" [global]="global"> </chat-banner-chat>
		</ng-container>
	</ng-container>
	
	<splash-screen
		*ngIf="config.showSplashScreen"
		[style.--splash-screen-bg]="'url(' + preferences.splashScreen + ')'"
		[style.--splash-screen-bg-mobile]="
			'url(' + preferences.splashScreenMobile + ')'
		"
	>
		<div class="spinner">
			<div class="bbwidget-bounce1"></div>
			<div class="bbwidget-bounce2"></div>
			<div class="bbwidget-bounce3"></div>
		</div>
	</splash-screen>
</ng-container>

