<div class="flex-col bbwidget-message">
	<div
		class="bbwidget-bubble-chat-question"
		matTooltipClass="mat-tooltip"
		[matTooltip]="message.date"
		matTooltipPosition="below"
		[style.--botMessageBackgroundColor]="
			config.botMessageBackgroundColor | safe: 'style'
		"
		[style.--botMessageTextColor]="config.botMessageTextColor | safe: 'style'"
		[innerHTML]="message.text | linkify"
	></div>
	<div
		class="flex flex-wrap w-auto"
		[ngClass]="{
			'flex-col': (message.quick_reply.message.quick_replies.length > 2 && chatView != 'BANNER'),
			'max-w-[85%]': chatView != 'BANNER',
			'max-w-full': chatView == 'BANNER'
		}"
	>
		<button
			mat-raised-button
			*ngFor="
				let q_reply of message.quick_reply.message.quick_replies;
				let last = last
			"
			[class.mr-2]="
				(message.quick_reply.message.quick_replies.length === 2 && !last || chatView == 'BANNER')
			"
			class="rounded-[20px] border-2 mb-[13px] border-[#b7b7b7d1] truncate w-fit max-w-full"
			(click)="sendMessage.emit(q_reply)"
		>
			<img
				src="{{ q_reply.image_url }}"
				*ngIf="q_reply.image_url"
				class="w-5 h-5 m-[2px]"
			/>
			{{ q_reply.title }}
		</button>
	</div>
</div>
