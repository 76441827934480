<div
	class="bbwidget-section-chat-header"
	[style.--toolbarChatBackgroundColorFirst]="
		config?.toolbarChatBackgroundColorFirst | safe: 'style'
	"
	[style.--toolbarChatBackgroundColorSecond]="
		config?.toolbarChatBackgroundColorSecond | safe: 'style'
	"
	[style.--toolbarChatBackgroundColorThird]="
		config?.toolbarChatBackgroundColorThird | safe: 'style'
	"
	[style.--toolbarChatContentColor]="
		config?.toolbarChatContentColor | safe: 'style'
	"
>
	<div style="display: flex; align-items: center">
		<div
			class="bbwidget-toolbar-back-button"
			(click)="goBack()"
			*ngIf="!config.showOnlyChat"
		>
			<mat-icon inline="true" class="toolbar-icon animated zoomIn shadow-custom"
				>chevron_left</mat-icon
			>
		</div>
		<div
			class="bbwidget-background-header-icon"
			[style.--toolbarLogoBackground]="config?.toolbarLogoBackground"
		>
			<img class="bbwidget-img-class" [src]="config?.toolbarLogo" />
		</div>
		<span class="bbwidget-title">{{
			config?.toolbarChatText | translate
		}}</span>
		<div style="font-size: 24px; padding-bottom: 8px">👋</div>
	</div>
	<div class="flex">
		<mat-menu #emojiMenu="matMenu" yPosition="above" xPosition="before">
			<ng-container *ngIf="config.chatView !== 'WEB'">
				<button mat-menu-item (click)="toogleSizeScreen()">
					<mat-icon>
						{{ helpResize === "in" ? "fullscreen_exit" : "close_fullscreen" }}
					</mat-icon>
					<span>{{
						(helpResize === "in" ? "SCALE_UP" : "SCALE_DOWN") | translate
					}}</span>
				</button>
			</ng-container>
			@if (config?.notificationAudio) {
				<button mat-menu-item (click)="toogleSound()">
					<mat-icon>
						{{
							!isSoundNotication ? "notifications_active" : "notifications_off"
						}}
					</mat-icon>
					<span>
						{{
							(!isSoundNotication ? "TURN_ON_SOUND" : "MUTE") | translate
						}}</span
					>
				</button>
			}
			<button mat-menu-item (click)="cleanChat()">
				<mat-icon> restart_alt </mat-icon>
				<span>{{ "RESTART" | translate }}</span>
			</button>
		</mat-menu>
		<button
			mat-icon-button
			[matMenuTriggerFor]="emojiMenu"
			*ngIf="config.showToolbarMenu"
		>
			<mat-icon>more_vert</mat-icon>
		</button>
		<button
			mat-icon-button
			(click)="close()"
			*ngIf="config.chatView == 'BUBBLE'"
		>
			<mat-icon>keyboard_arrow_down</mat-icon>
		</button>
	</div>
</div>
