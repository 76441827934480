import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
// import { GoogleAnalyticsService } from "ngx-google-analytics";
import { SplashScreenService } from "../@chatBot/service/splash-screen/splash-screen.service";
import { TranslateService } from "@ngx-translate/core";
import { Global } from "./core/global-variables/global";
import { environment } from "../../src/environments/environment";
import { PreferenceService } from "./core/service/preference.service";
import { LanguageService } from "./core/service/language/language.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
	// changeDetection: ChangeDetectionStrategy.OnPush,

	// encapsulation: ViewEncapsulation.ShadowDom
})
export class AppComponent implements OnInit, OnChanges {
	@Input() id: string = null;
	@Input() view: string = null;
	@Input() config: any = null;

	appUrl: string = "";
	eventsSubject: Subject<void> = new Subject<void>();
	startChat = false;

	constructor(
		private global: Global,
		public _splashScreenService: SplashScreenService,
		private translateService: TranslateService,
		private languageService: LanguageService,
		private _preferenceService: PreferenceService
	) { }

	ngOnInit() {
		const urlParams = new URLSearchParams(window.location.search);
		this.id = urlParams.get('id');
		this.view = urlParams.get('view');
		this.getPreference();
	}

	ngOnChanges(changes: SimpleChanges): void {

		if ('config' in changes) {
			this.config = changes.config.currentValue;
			this.setPreference();
		}
		if ('id' in changes) {
			this.id = changes.id.currentValue;
			this.getPreference();
		}
		if ('view' in changes) {
			this.view = changes.view.currentValue;
			this.getPreference();
		}

	}

	getPreference() {
		if (this.id && this.view) {
			this._preferenceService.getPreferences(this.id, this.view.toLowerCase()).subscribe((config) => {
				this.config = config;

				if (environment.production) {
					this.global.appUrl =
						this.config.webhooks[
							environment.production ? "production" : "development"
						].appUrl || "";
					this.appUrl = this.global.appUrl;

				}

				if (config.notificationAudio) {
					const isSoundNotication = localStorage.getItem('isSoundActive') ? (localStorage.getItem('isSoundActive').toLowerCase() === 'true' ? true : false) : false;
					let audio: any = document.getElementById("saludo");
					audio.muted = !isSoundNotication;
				}


				this.translateService.addLangs(config.languages);

				const defaultdefaultLanguage = sessionStorage.getItem(
					`bb_widget_language_${this.id}`
				) || config.defaultLanguage || navigator.language.split('-')[0];

				this.languageService.getTranslation(defaultdefaultLanguage).subscribe(() => {
					this.translateService.use(defaultdefaultLanguage);
					this._splashScreenService.hide();
					this.startChat = true;
				});
				// this.gaService.pageView("/home");


				// this._changeDetectorRef.markForCheck();
			})
		}
	}

	setPreference() {
		this.config['id'] = this.id;
		this.config['view'] = this.view.toUpperCase();
		this._preferenceService.config = this.config;
		// this._changeDetectorRef.markForCheck();
	}

}
